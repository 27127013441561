@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";

html {
	background: #d3d3d3 url('../img/bg/bg-2.png') no-repeat center top;
	background-size: cover;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	overflow-y: scroll;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-black;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	min-height: 100vh;
	position: relative;
}

a {
	color: $color-black;

	@include hover-focus {
		color: $color-black;
	}
}

.btn {
	border: 2px solid $color-white;
	color: $color-white;
	display: inline-block;
	font-size: rem(18);
	font-weight: 500;
	letter-spacing: letter-spacing(250);
	padding: rem(10) rem(20);
	text-align: center;
	text-indent: text-indent(250);
	vertical-align: middle;

	@include hover-focus {
		background: $color-white;
		color: $color-black;
	}
}

#bgs {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;

	.bg {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;

		&:not(:first-child) {
			display: none;
		}
	}

	> img {
		margin: 0 auto;
		max-height: 90vh;
		width: auto;
	}

	> video {
		height: 100%;
		object-fit: cover;
		object-position: center top;
		width: 100%;
	}
}

header, footer {
	.container {
		max-width: 100%;
		padding: 0 rem(20);
	}
}

header {
	background: rgba(31, 25, 25, 0.3);
	left: 0;
	padding: rem(20) 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;

	nav {
		> ul {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				display: inline-block;
				font-size: rem(18);
				font-weight: 500;
				letter-spacing: letter-spacing(200);
				text-indent: text-indent(200);
				vertical-align: middle;

				> a {
					color: $color-black;

					@include hover-focus {
						color: $color-white;
					}
				}
			}
		}
	}
}

main {
	align-items: center;
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	position: relative;
	width: 100%;
	z-index: 2;

	section {
		padding: rem(100) 0;
		width: 100%;

		&:not(:first-child) {
			display: none;
		}

		.container {
			max-width: rem(900);
		}

		.owl-carousel {
			padding: 0 rem(60);

			.owl-nav {
				> button {
					color: $color-white;
					font-size: rem(30);
					opacity: 1;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					@include hover-focus {
						opacity: 0.8;
					}

					&.owl-prev {
						left: 0;
					}

					&.owl-next {
						right: 0;
					}
				}
			}
		}

		&#home {
			h1 {
				margin: 0;
				transform: translateY(35%);

				> img {
					margin: 0 auto;
					width: rem(900);
				}
			}
		}

		&#music {
			text-align: center;

			h2 {
				font-size: rem(25);
				font-style: italic;
				font-weight: 500;
				line-height: line-height(30, 25);
				margin: 0 0 rem(15) 0;
				color: $color-white;
				text-shadow: 2px 5px 5px $color-black;
			}

			.owl-carousel {
				/*margin-top: calc(100vh - #{rem(175)});*/
				margin-top: rem(50);
				position: relative;

				/*&:before {
					animation: bounce 500ms linear infinite alternate;
					color: $color-white;
					content: "\f063";
					display: inline-block;
					font-family: "Font Awesome 5 Pro";
					font-size: rem(30);
					left: 0;
					position: absolute;
					right: 0;
					top: rem(-50);
				}*/
			}


			.btn{
			}

			@keyframes bounce {
				0% {
					transform: translateY(0);
				}
				100% {
					transform: translateY(-10px);
				}
			}

			.item-album {
				img {
					margin: 0 auto rem(30) auto;
					width: rem(350);
				}
			}
		}

		&#videos {
			.owl-carousel {
				.owl-video-wrapper {
					overflow: hidden;
					padding-bottom: 56.25%;
					position: relative;

					iframe,
					.owl-video-tn {
						background-size: cover;
						bottom: 0;
						left: 0;
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}
		}

		&#shop {
			text-align: center;

			.owl-carousel {
				img {
					margin: 0 auto rem(30) auto;
					width: rem(500);
				}
			}

			.shop-link {
				display: inline-block;
				margin-top: rem(40);
				text-decoration: underline;
				color: $color-white;

				@include hover-focus {
					text-decoration: none;
				}
			}
		}
	}
}

footer {
	flex: 0 0 auto;
	padding: rem(20) 0;
	position: relative;
	width: 100%;
	z-index: 10;

	.container {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;

		@include phone-down {
			display: block;
			text-align: center;
		}

		> .socials {
			list-style: none;
			margin: 0;
			padding: 0;

			@include phone-down {
				margin-bottom: rem(15);
			}

			> li {
				display: inline-block;
				font-size: rem(22);
				vertical-align: middle;

				+ li {
					margin-left: rem(15);
				}

				> a {
					@include hover-focus {
						color: $color-white;
					}
					
					@include phone-down {
						color: $color-white;
						@include hover-focus {
							color: $color-black;
						}
					}
				}
			}
		}

		> .copyright {
			p {
				font-size: rem(12);
				line-height: line-height(16, 12);
				margin: 0;
				@include phone-down {
					color: $color-white;
				}

				a {
					
					@include phone-down {
						color: $color-white;
					}
					@include hover-focus {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

#tour{
	h2{
		font-size: 32px;
		text-align: center;
		text-transform: uppercase;
		font-weight: 400;
		margin: 0 0 60px;
	}
	#events{
		display: grid;
		gap: 30px;
		.event{
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: space-between;
			border-bottom: solid 1px #f3f3f3;
			padding-bottom: 30px;
			.event-date{
				flex: 1;
				text-align: center;
				max-width: max-content;
				span{
					display: block;
					font-weight: 700;
					&.event-date-month{
						font-size: 11px;
					}
					&.event-date-day{
						font-size: 20px;
					}
				}
			}
			.event-info{
				flex: 2;
				font-weight: 700;
				.event-venue{
					font-size: 18px;
					font-size: 14px;
					margin-bottom: 4px;
				}
				.event-location{
					font-size: 12px;
				}
			}
			.btn{
				background-color: $color-black;
				border: none;
				border-radius: 100px;
			}
		}
	}
	@include tablet-up{
		h2{
			font-size: 42px;
		}
		#events{
			.event{
				gap: 60px;
				.event-date{
					span{
						&.event-date-month{
							font-size: 18px;
						}
						&.event-date-day{
							font-size: 30px;
						}
					}
				}
				.event-info{
					.event-venue{
						font-size: 20px;
					}
					.event-location{
						font-size: 16px;
						font-weight: 400;
					}
				}
			}
		}
	}
}